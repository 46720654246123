<template>
  <div class="select-box d-flex">
    <div class="w-100 select-child-box">
      <h3>{{ $t('selectType.title') }}</h3>
      <div class="d-flex justify-space-around flex-wrap w-100 pad-100-0">
        <div class="stores-box">
          <div>
            <img src="@/assets/image/home/imgPersonal.png" width="200" alt="">
          </div>
          <div>
            <span>{{ $t('selectType.personalTips') }}</span>
          </div>
          <el-button style="margin-top: 50px;background: #4285f7;border: 1px solid #4285f7;color: white;padding:10px 30px;" @click="personalShopBtn()">{{ $t('selectType.personalText') }}</el-button>
        </div>
        <div class="stores-box">
          <div>
            <img src="@/assets/image/home/imgCompany.png" width="200" alt="">
          </div>
          <div>
            <span>{{ $t('selectType.companyTips') }}</span>
          </div>
          <el-button style="margin-top: 50px;background: #4285f7;border: 1px solid #4285f7;color: white;padding:10px 30px;" @click="enterpriseStoresBtn()">{{ $t('selectType.companyText') }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    personalShopBtn() {
      this.$router.push({ path: '/signIn/settled/crossBorderPersonal' })
    },
    enterpriseStoresBtn() {
      this.$router.push({ path: '/signIn/settled/enterpriseStores' })
    }
  }
}
</script>
<style scoped>
.select-box {
  padding: 100px 18.5%;
  background: #f7f9ff;
  box-sizing: border-box;
}
.stores-box {
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.select-child-box {
  background: #fff;
  padding: 35px 40px;
}
</style>
